import React from 'react'
import { Box, Grid, Flex } from '@chatterbug/aaron'
import ChatterbugInfoCard from 'src/components/ChatterbugInfoCard/ChatterbugInfoCard'
import { useTranslate } from 'react-polyglot'
import VoucherVerification, {
  BodyProps,
} from 'src/components/VoucherVerification/VoucherVerification'
import VoucherVerifyForm from 'src/components/VoucherVerification/subcomponents/VoucherVerifyForm'
import { getMailTo } from 'src/lib/app'
import { VoucherData } from 'src/types'

export type Props = {
  content: BodyProps
  onVerify: (response: VoucherData) => void
}

const Verify: React.FC<Props> = ({ content, onVerify }) => {
  const t = useTranslate()

  return (
    <Grid
      maxWidth={{ _: '752px', laptop: '100%' }}
      gridTemplateAreas={{
        _: `'MAIN''HELP''INFO'`,
        laptop: `'MAIN INFO''HELP HELP'`,
      }}
      columns={{ _: '1fr', laptop: '2fr 1fr' }}
      justifyContent="center"
      gridGap="3x"
    >
      <Box
        as="span"
        sx={{
          gridArea: 'MAIN',
        }}
      >
        <VoucherVerification content={content}>
          <VoucherVerifyForm onVerify={onVerify} />
        </VoucherVerification>
      </Box>
      <Box
        textAlign="center"
        as="p"
        variant="text.paragraph"
        sx={{
          gridArea: 'HELP',
        }}
        dangerouslySetInnerHTML={{
          __html: t(
            "Having problems with verifying your voucher?<br/> Contact our <a class='link' href=%{mailTo}>Support Team</a>",
            { mailTo: getMailTo() }
          ),
        }}
      />
      <Flex
        flex="1"
        as="span"
        // column on mobile to span horizontally 100%
        // row on laptop and above to span vertically 100%
        flexDirection={{ _: 'column', laptop: 'row' }}
        sx={{
          gridArea: 'INFO',
        }}
      >
        <ChatterbugInfoCard />
      </Flex>
    </Grid>
  )
}

export default Verify
