import React from 'react'
import { useTranslate } from 'react-polyglot'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import {
  getLessonsGiftVoucherParams,
  getLessonsLoginUrl,
  getLessonsSignupUrl,
  getStreamsGiftVoucherParams,
  getStreamsLoginUrl,
  getStreamsSignupUrl,
} from 'src/lib/app'
import { useLocale } from 'src/lib/I18nContext'

import { Grid } from '@chatterbug/aaron'
import { Product } from 'src/types'

export type Props = {
  voucherCode: string
  product: Product
}

function addUrlParam(url: string, param: string) {
  const separator = url.indexOf('?') > -1 ? '&' : '?'
  return url + separator + param 
}

const VoucherRedeemButtons: React.FC<Props> = ({ voucherCode, product }) => {
  const t = useTranslate()
  const locale = useLocale()
  const giftVoucherParam =
    product === Product.streams
      ? getStreamsGiftVoucherParams(voucherCode)
      : getLessonsGiftVoucherParams(voucherCode)
  const loginUrl =
    product === Product.streams
      ? getStreamsLoginUrl(locale)
      : getLessonsLoginUrl()

  const signupUrl =
    product === Product.streams
      ? getStreamsSignupUrl(locale)
      : getLessonsSignupUrl(locale)

  return (
    <Grid
      gridTemplateColumns={{ _: 'auto', tablet: 'auto auto' }}
      as="section"
      gridGap="3x"
    >
      <Button
        label={t('Create new account')}
        href={addUrlParam(signupUrl, giftVoucherParam)}
        variant={BUTTON_VARIANTS.primary}
        height="44px"
        width={{ _: '100%', tablet: 'auto' }}
      />
      <Button
        href={addUrlParam(loginUrl, giftVoucherParam)}
        label={t('Log in')}
        variant={BUTTON_VARIANTS.neutral}
        height="44px"
        width={{ _: '100%', tablet: 'auto' }}
      />
    </Grid>
  )
}

export default VoucherRedeemButtons
