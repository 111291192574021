import React from 'react'

import { Box, Flex, Grid, Image } from '@chatterbug/aaron'
import GiftCreator from '../GiftCreator/GiftCreator'

export type BodyProps = {
  title: string
  imageUrl: string
  body: string
}

const Body: React.FC<BodyProps> = ({ title, imageUrl, body }) => {
  return (
    <Grid
      gridGap="3x"
      justifyItems="center"
      textAlign="center"
      width={{ _: '100%', tablet: '600px' }}
      position="relative"
    >
      <Image src={imageUrl} alt="" />
      <GiftCreator />
      <Box as="h1" variant="text.h3">
        {title}
      </Box>
      <Box as="p" variant="text.paragraph">
        {body}
      </Box>
    </Grid>
  )
}

export type Props = {
  fadeIn?: boolean
  content: BodyProps
  children?: JSX.Element
}

const VoucherVerification: React.FC<Props> = ({
  fadeIn = false,
  content,
  children,
}) => {
  return (
    <Flex
      flexDirection="column"
      boxShadow="overlay"
      px="4x"
      py={{ _: '4x', tablet: '6x' }}
      borderRadius="md"
      justifyContent="center"
      height="100%"
      bg="white100"
    >
      <Grid
        width={{ _: '100%', tablet: '600px' }}
        disabled={!fadeIn}
        gridGap="3x"
      >
        <Body
          title={content.title}
          imageUrl={content.imageUrl}
          body={content.body}
        />
        <Flex
          pt="1x"
          flexDirection={{ _: 'column', tablet: 'row' }}
          justifyContent="center"
        >
          {children}
        </Flex>
      </Grid>
    </Flex>
  )
}

export default VoucherVerification
