import React, { FormEvent, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Sentry from '@sentry/browser'
import { VoucherData } from 'src/types'
import camelCase from 'lodash/camelCase'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { useLocale } from 'src/lib/I18nContext'

import { Box, Grid, Flex } from '@chatterbug/aaron'
import Input, { InputError } from 'src/components/Input/Input'

export interface APIVoucherData {
  can_be_redeemed: boolean
  title: string
  units: number
  purchaser_name: string
  recipient_name: string
  code: string
  message: string
  sub_title: string
}

export type Props = {
  onVerify: (response: VoucherData) => void
}

const camelCaseReponse = (responseObj: APIVoucherData): VoucherData => {
  return Object.entries(responseObj).reduce((acc, [key, value]) => {
    return { ...acc, [camelCase(key)]: value }
  }, {} as VoucherData)
}

const VoucherVerifyForm: React.FC<Props> = ({ onVerify }) => {
  const t = useTranslate()
  const locale = useLocale()

  const [voucherText, setVoucherText] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [enabled, setEnabled] = useState(true)

  const REDEEM_ERROR = t('Voucher cannot be redeemed')
  const GENERIC_ERROR = t('There was a problem, try again')

  const handleChange = (event: any) => {
    if (errorMessage) {
      setErrorMessage('')
    }
    setVoucherText(event.target.value)
  }

  //replace with useCommand
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setEnabled(false)
    const url = `${process.env.API_URL}/gift_vouchers/${voucherText}`
    try {
      const response = await fetch(url)
      const data = await response.json()
      if (response.ok) {
        const camelCasedResponse = camelCaseReponse(data)
        if (camelCasedResponse.canBeRedeemed) {
          onVerify(camelCasedResponse)
        } else {
          setErrorMessage(REDEEM_ERROR)
        }
      } else if (response.status === 404) {
        setErrorMessage(REDEEM_ERROR)
      } else {
        throw new Error('A problem occured in verifying the voucher')
      }
      setEnabled(true)
    } catch (error) {
      console.log('error', error)
      setErrorMessage(GENERIC_ERROR)
      Sentry?.captureException(error)
      setEnabled(true)
    }
  }
  return (
    <Grid
      as="form"
      onSubmit={handleSubmit}
      gridTemplateRows="repeat(2, auto)"
      gridTemplateAreas={{
        _: `'INPUT''ERROR''BUTTON'`,
        tablet: `'INPUT BUTTON''ERROR ERROR'`,
      }}
      gridTemplateColumns="1fr auto"
    >
      <Flex
        flexDirection="column"
        as="span"
        sx={{
          gridArea: 'INPUT',
        }}
        bg="white100"
      >
        <Input
          onChange={handleChange}
          name="voucherCode"
          hasError={errorMessage ? true : false}
          hasVisibleLabel
          placeholder={t('eg. CHT-1349')}
          label={t('Voucher Code')}
          type="text"
          required={true}
          value={voucherText}
        />
      </Flex>
      <Box
        as="span"
        sx={{
          gridArea: 'ERROR',
        }}
        aria-live="assertive"
      >
        {errorMessage && <InputError text={errorMessage} />}
      </Box>
      <Flex
        flexDirection="column"
        sx={{
          gridArea: 'BUTTON',
        }}
        ml={{ _: '0', tablet: '3x' }}
        mt={{ _: '3x', tablet: '0' }}
      >
        <Button
          data-l1={locale}
          disabled={!enabled}
          type="submit"
          href={null}
          variant={BUTTON_VARIANTS.primary}
          height="44px"
          label={t('Verify')}
        />
      </Flex>
    </Grid>
  )
}

export default VoucherVerifyForm
