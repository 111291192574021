import React from 'react'
import { Box, Grid, Flex } from '@chatterbug/aaron'
import VoucherVerification, {
  BodyProps,
} from 'src/components/VoucherVerification/VoucherVerification'
import VoucherRedeemButtons from 'src/components/VoucherVerification/subcomponents/VoucherRedeemButtons'
import GiftCard, { GiftCardProps } from 'src/components/GiftCard/GiftCard'
import FadeIn from 'src/components/FadeIn/FadeIn'
import { useTranslate } from 'react-polyglot'
import { Product } from 'src/types'

export type Props = {
  content: BodyProps
  title: string
  units: number
  purchaserName: string
  recipientName: string
  code: string
  message: string
  product: Product
  giftSize: GiftCardProps['giftSize']
}

const Redeem: React.FC<Props> = ({
  content,
  title,
  units,
  purchaserName,
  recipientName,
  code,
  message,
  giftSize,
  product,
}) => {
  const t = useTranslate()

  return (
    <FadeIn>
      <Grid
        gridTemplateAreas={{
          _: `'MAIN''INFO'`,
          tablet: `'MAIN INFO'`,
        }}
        columns={{ _: '1fr', tablet: '2fr 1fr' }}
        justifyContent="center"
        gridGap="3x"
      >
        <Box
          as="span"
          sx={{
            gridArea: 'MAIN',
          }}
        >
          <VoucherVerification content={content}>
            <VoucherRedeemButtons voucherCode={code} product={product} />
          </VoucherVerification>
        </Box>
        <Flex
          flex="1"
          as="span"
          sx={{
            gridArea: 'INFO',
          }}
        >
          <GiftCard
            product={product}
            description={title}
            giftSize={giftSize}
            details={{
              title: units
                ? t(
                    '%{smart_count} Live Lesson |||| %{smart_count} Live Lessons',
                    {
                      smart_count: units,
                    }
                  )
                : t('Gift Voucher'),
              code,
              message,
              recipient: recipientName,
              sender: purchaserName,
            }}
          />
        </Flex>
      </Grid>
    </FadeIn>
  )
}

export default Redeem
