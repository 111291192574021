import React from 'react'
import { PageProps } from 'gatsby'
import { useTranslate } from 'react-polyglot'
import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import Header from 'src/components/Header/Header'
import PageContainer from 'src/components/PageContainer/PageContainer'
import { getGiftsNavigationItems } from 'src/lib/navigation'
import { PageContext } from 'src/types'
import { Flex } from '@chatterbug/aaron'
import VerifyAndRedeem from 'src/sections/VerifyAndRedeem/VerifyAndRedeem'
import { useLocale } from 'src/lib/I18nContext'

const VouchersRedeemPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        title={t('Redeem a voucher')}
        description={t('gifts_hero.sub_header')}
        // TODO: get another OG image that suits both Streams and Lessons gifts
        socialImage={`/assets/global/og-images/gifts/lessons/${locale}.jpg`}
        pageContext={pageContext}
      />
      <PageContainer>
        <Header
          backgroundType="light"
          l2Code={null}
          items={getGiftsNavigationItems(t)}
          canonicalPath={pageContext.canonicalPath}
        />
        <Flex as="section" my={88} justifyContent="center">
          <VerifyAndRedeem />
        </Flex>
      </PageContainer>
    </Layout>
  )
}

export default VouchersRedeemPage
