import { PolyglotFn } from 'src/types'
import confirmationIcon from './assets/confirmation.svg'
import verificationIcon from './assets/verify.svg'
// import { BodyProps } from 'src/components/VoucherVerification/VoucherVerification'
//TODO: figure out how to enforce the return type from the getStepsContent

export const getStepsContent = (t: PolyglotFn) => {
  return {
    verification: {
      title: t('voucher_verification.title'),
      imageUrl: verificationIcon,
      body: t('voucher_verification.body'),
    },
    confirmation: {
      title: t('voucher_confirmation.title'),
      imageUrl: confirmationIcon,
      body: t('voucher_confirmation.body'),
    },
  }
}
