import styled from 'styled-components'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { getLessonsGiftupProducts } from 'src/data/vouchers/lessonsGiftupProducts'
import { getStreamsGiftupProducts } from 'src/data/vouchers/streamsGiftupProducts'
import { useLocale } from 'src/lib/I18nContext'
import { Product } from 'src/types'
import theme from 'src/lib/theme'
import { Box } from '@chatterbug/aaron'
import Dropdown from '../Dropdown/Dropdown'
import { z } from 'zod'

const orderResponseSchema = z.object({
  giftCards: z.array(
    z.object({
      code: z.string(),
    })
  ),
})

/**
 * Dev helper to create a gift from the Redeem page.
 * Just click on the licorn gift to start the magic ✨
 *
 * Note: Due to dead code elimination, this code is not part of our production bundle.
 */
const GiftCreator =
  process.env.NODE_ENV === 'development'
    ? () => {
        const [error, setError] = useState<string>()
        const [code, setCode] = useState<string>()
        const [loading, setLoading] = useState(false)
        const [isOpen, setIsOpen] = useState(false)

        const locale = useLocale()
        const t = useTranslate()

        useEffect(() => {
          if (!process.env.LESSONS_GIFTUP_API_KEY) {
            setError(
              '`LESSONS_GIFTUP_API_KEY` need to be defined in your `.env.development`'
            )
            return
          }
          if (!process.env.STREAMS_GIFTUP_API_KEY) {
            setError(
              '`STREAMS_GIFTUP_API_KEY` need to be defined in your `.env.development`'
            )
          }
        }, [])

        const gifts = useMemo(
          () => [
            ...getLessonsGiftupProducts(locale, t),
            ...getStreamsGiftupProducts(locale, t),
          ],
          [locale, t]
        )

        const giftOptions = useMemo(
          () =>
            gifts.map((g) => ({
              value: g.productId,
              label: g.label,
            })),
          [gifts]
        )

        const orderAGift = useCallback(
          (productId: string) => {
            const gift = gifts.find((g) => g.productId === productId)
            if (!gift) {
              throw new Error(`No gift found with "${productId}" as productId`)
            }
            setLoading(true)
            const token =
              gift.product === Product.lessons
                ? process.env.LESSONS_GIFTUP_API_KEY
                : process.env.STREAMS_GIFTUP_API_KEY

            fetch('https://api.giftup.app/orders', {
              method: 'POST',
              body: JSON.stringify({
                purchaserName: 'Unicorn',
                quantity: 1,
                itemDetails: [
                  {
                    quantity: 1,
                    id: productId,
                  },
                ],
                recipientDetails: {
                  message:
                    'You may have stopped believing in unicorns, but they never stopped believing in you. 🦄✨',
                },
              }),
              headers: {
                'Content-Type': 'application/json',
                Accepts: 'application/json',
                Authorization: `Bearer ${token}`,
                'x-giftup-testmode': 'true',
              },
            })
              .then((res) => res.json())
              .then((res) => {
                const validatedRes = orderResponseSchema.parse(res)
                setCode(validatedRes.giftCards[0].code)
              })
              .catch((err) => setError(err.message))
              .finally(() => setLoading(false))
          },
          [gifts]
        )

        if (!isOpen) {
          return (
            <Box
              title="Do you want this gift?"
              height={30}
              width={35}
              backgroundColor="transparent"
              sx={{
                position: 'absolute',
                top: 89,
                cursor: 'pointer',
              }}
              onClick={() => setIsOpen(true)}
            />
          )
        }

        if (error) {
          return (
            <Bubble>
              <p>Oh oh, something is not working!</p>
              <p>{error}</p>
            </Bubble>
          )
        }

        if (code) {
          return (
            <Bubble>
              <p>I have a gift for you!</p>
              <p>Your code: {code}</p>
            </Bubble>
          )
        }

        if (loading) {
          return (
            <Bubble>
              <p>Wait here! I have a surprise for you!</p>
            </Bubble>
          )
        }

        return (
          <Bubble>
            <p>Hey! What kind of gift do you want?!</p>
            <Dropdown
              buttonWidth={240}
              value={null}
              placeholder="Select a gift"
              items={giftOptions}
              onChange={(selectedGift) =>
                selectedGift && orderAGift(selectedGift.value)
              }
            />
          </Bubble>
        )
      }
    : () => null

const Bubble = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  top: 20px;
  left: calc(50% + 60px);
  background: ${theme.colors.pink51};
  color: #ffffff;
  font-size: 16px;
  padding: 4px;
  width: 300px;
  height: 120px;
  border-radius: 10px;
  padding: 0px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent ${theme.colors.pink51};
    border-width: 20px 20px 20px 0;
    top: 50%;
    left: -20px;
    margin-top: -20px;
  }
`

export default GiftCreator
