import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { getStepsContent } from 'src/data/redeemVerify/verifyAndRedeemData'
import FadeIn from 'src/components/FadeIn/FadeIn'
import Verify from './subcomponents/Verify/Verify'
import Redeem from './subcomponents/Redeem/Redeem'
import { VoucherData } from 'src/types'

enum Steps {
  verification = 'verification',
  confirmation = 'confirmation',
}

const VerifyAndRedeem: React.FC = () => {
  const [step, setStep] = useState(Steps.verification)
  const [voucherData, setVoucherData] = useState<VoucherData>()
  const t = useTranslate()
  const data = getStepsContent(t)

  const handleVerifyVoucher = (response: VoucherData) => {
    setVoucherData(response)
    setStep(Steps.confirmation)
  }

  const content = data[step]

  return (
    <FadeIn sx={{ minHeight: '700px' }}>
      {voucherData && step === Steps.confirmation ? (
        <Redeem content={content} {...voucherData} giftSize="m" />
      ) : (
        <Verify content={content} onVerify={handleVerifyVoucher} />
      )}
    </FadeIn>
  )
}

export default VerifyAndRedeem
