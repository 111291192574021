import React from 'react'
import { Box } from '@chatterbug/aaron'

import GiftCard from '../GiftCard/GiftCard'
import { GiftProductItem, Product } from 'src/types'

const TOP_OFFSET = 38
const TOTAL_WIDTH = 614
const TOTAL_HEIGHT = 280
const CARD_WIDTH = 350
const CARD_HEIGHT = 276
const CARD_SCALE = 0.6

export type GiftShowcaseProps = {
  gifts: [GiftProductItem, GiftProductItem, GiftProductItem]
}

const GiftShowcase: React.FC<GiftShowcaseProps> = ({
  gifts: [gift1, gift2, gift3],
}) => (
  <Box
    sx={{
      position: 'relative',
      width: TOTAL_WIDTH,
      height: TOTAL_HEIGHT,
      display: 'flex',
    }}
  >
    <GiftCard
      product={gift1.product}
      description={getDescription(gift1)}
      giftSize={gift1.giftSize}
      sx={{
        width: CARD_WIDTH,
        position: 'absolute',
        left: 0,
        top: TOP_OFFSET,
        transformOrigin: 'top left',
        transform: `scale(${CARD_SCALE})`,
      }}
    />
    <GiftCard
      product={gift2.product}
      description={getDescription(gift2)}
      giftSize={gift2.giftSize}
      sx={{
        width: CARD_WIDTH,
        position: 'absolute',
        right: 0,
        top: TOP_OFFSET,
        transformOrigin: 'top right',
        transform: `scale(${CARD_SCALE})`,
      }}
    />
    <FrontCardShadow />
    <GiftCard
      product={gift3.product}
      description={getDescription(gift3)}
      giftSize={gift3.giftSize}
      sx={{
        width: CARD_WIDTH,
        transform: 'translate(-50%, 0)',
        position: 'absolute',
        left: '50%',
      }}
    />
  </Box>
)

const getDescription = (gift: GiftProductItem) =>
  gift.product === Product.lessons ? gift.label : gift.funName

/**
 * Front card’s shadow (apply on other cards only)
 */
const FrontCardShadow = () => (
  <Box
    sx={{
      width: '100%',
      position: 'absolute',
      top: TOP_OFFSET,
      height: CARD_HEIGHT * CARD_SCALE,
      overflow: 'hidden',
    }}
  >
    <Box
      sx={{
        width: CARD_WIDTH,
        height: '100%',
        transform: 'translate(-50%, 0)',
        position: 'absolute',
        left: '50%',
        boxShadow: '0px 0 40px rgba(0, 0, 0, 0.6)',
      }}
    />
  </Box>
)

export default GiftShowcase
