import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'
import { useTranslate } from 'react-polyglot'

import GiftShowcase from '../GiftShowcase/GiftShowcase'
import { useLocale } from 'src/lib/I18nContext'
import { getLessonsGiftupProducts } from 'src/data/vouchers/lessonsGiftupProducts'
import { getStreamsGiftupProducts } from 'src/data/vouchers/streamsGiftupProducts'

const ChatterbugInfoCard: React.FC = () => {
  const t = useTranslate()
  const locale = useLocale()

  const lessonsGifts = getLessonsGiftupProducts(locale, t)
  const streamsGifts = getStreamsGiftupProducts(locale, t)

  return (
    <Flex
      as="section"
      bg="white100"
      boxShadow="raised"
      flexDirection="column"
      p="3x"
      alignItems="center"
      borderRadius="md"
      data-testid="chatterbug-info-card"
    >
      <Box
        mb="4x"
        height={190}
        sx={{
          transform: 'scale(0.55)',
        }}
      >
        <GiftShowcase
          gifts={[lessonsGifts[0], lessonsGifts[1], streamsGifts[2]]}
        />
      </Box>
      <Box
        as="h2"
        variant="text.h3"
        fontWeight="regular"
        mb="3x"
        dangerouslySetInnerHTML={{
          __html: t('cb_info_card.title'),
        }}
      />
      <Box
        as="p"
        variant="text.paragraph"
        dangerouslySetInnerHTML={{
          __html: t('cb_info_card.description'),
        }}
      />
    </Flex>
  )
}

export default ChatterbugInfoCard
